import axios from "axios";

//LIVE
// export const appBaseURL = `https://apiv2.evpower.my/`    
// console.log = function () { };

//DEV
export const appBaseURL = `https://bev2.evpower.my/`    

//LOCAL
// export const appBaseURL = `//192.168.0.23:5000/` 

const Api = () => axios.create({ 
    baseURL: appBaseURL
});

export const env = "prod"


export const Alert = (message, type,) => {
    let div = document.createElement('div');
    div.classList.add("custom_alert")
    div.classList.add(type)
    div.innerHTML += `<p>${message}</p>`
    document.body.append(div)
    setTimeout(() => {
        document.body.querySelector(".custom_alert").remove()
    }, 3000);
}

export const isLogIn = () => {
    if (localStorage.getItem('EV_TOKEN')) {
        return { isToken: true, token: localStorage.getItem('EV_TOKEN') };
    } else {
        return { isToken: false, token: '' };
    }
};
export const setToken = (token, data) => {
    localStorage['EV_TOKEN'] = token;
    // localStorage['AUTH_DATA'] = JSON.stringify(data);
};

const headers = () => {
    if (isLogIn().token) {
        return {
            headers: {
                "token": isLogIn().token,
            }
        }
    } else {
        return {
            headers: {}
        }
    }
}

//Handle API response
function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response
    }
    if (response.status === 500 || response.status === 404) {
        return { response: { data: { error: response.data.error } }, error: true }
    }
    else {
        return { response: { data: { error: response.data.error } }, error: true }
    }
}


let errorData = (err) => {
    return {
        error: err.response && err.response.data ?
            (typeof err.response.data.error == "string" ?
                err.response.data.error : err.response.data.error?.message) : "Something went wrong"
    }
}
const tokenError = (err) => {
    if (err.response?.data.errorCode === "TOKEN_EXPIRED" || err.response?.data.error === "Session Expired" || err.response?.data.error === "jwt expired" || err.response?.data.errorCode === "USER_NOT_EXIST") {
        setTimeout(() => {
            localStorage.removeItem("EV_TOKEN")
            window.location.reload(false);
        }, 2000);
        // window.open("/login") 
    }
}



//API methods Admin
export const GetApi = (url) => {
    return Api().get(url, headers()).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}




export const PostApi = async (url, body) => {
    return Api().post(url, body, headers()).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApi = async (url, body) => {
    return Api().put(url, body, headers()).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApi = async (url) => {
    return Api().delete(url, headers()).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: { 
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}